import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const routes = [
  // View Admin Home
  {
    path: "/",
    name: "HomePage",
    meta: { auth: true, permission: "get-admin-counter"},
    component: () => import("@/views/dashboard/DashboardPage.vue"),
  },
  // View Auth/Login
  {
    path: "/login",
    name: "AuthLoginPage",
    props: true,
    meta: { auth: false, permission: null },
    component: () => import("@/views/LoginPage.vue"),
  },
  // View Gallery
  {
    path: "/gallery",
    name: "GalleryPage",
    props: true,
    meta: { auth: true, permission: null},
    component: () => import("@/views/gallery/GalleryPage.vue"),
  },
  // View Media
  {
    path: "/media-page",
    name: "MediaPage",
    props: true,
    meta: { auth: true, permission: null},
    component: () => import("@/views/media/MediaPage.vue"),
  },
  // View Cats Media
  {
    path: "/media-category",
    name: "CatsMediaPage",
    props: true,
    meta: { auth: true, permission: null},
    component: () => import("@/views/catsmedia/CatsMediaPage.vue"),
  },
  // View Cats Gallery
  {
    path: "/gallery-category",
    name: "CatsGalleryPage",
    props: true,
    meta: { auth: true, permission: null},
    component: () => import("@/views/catsgallery/CatsGalleryPage.vue"),
  },
  // View Compartments
  {
    path: "/compartments",
    name: "CompartmentsPage",
    props: true,
    meta: { auth: true, permission: null},
    component: () => import("@/views/compartments/CompartmentsPage.vue"),
  },
  // Create Compartments
  {
    path: "/compartments/create",
    name: "CreateCompartmentPage",
    props: true,
    meta: { auth: true, permission: "add-compartment"},
    component: () => import("@/views/compartments/CreateCompartmentPage.vue"),
  },
  // Edit Compartments
  {
    path: "/compartments/edit/:id",
    name: "EditCompartmentPage",
    props: true,
    meta: { auth: true, permission: "edit-compartment"},
    component: () => import("@/views/compartments/EditCompartmentPage.vue"),
  },
  // View Departments
  {
    path: "/departments",
    name: "DepartmentsPage",
    props: true,
    meta: { auth: true, permission: null},
    component: () => import("@/views/departments/DepartmentsPage.vue"),
  },
  // Create Compartments
  {
    path: "/departments/create",
    name: "CreateDepartmentPage",
    props: true,
    meta: { auth: true, permission: "add-compartment"},
    component: () => import("@/views/departments/CreateDepartmentPage.vue"),
  },
  // Edit Compartments
  {
    path: "/departments/edit/:id",
    name: "EditDepartmentPage",
    props: true,
    meta: { auth: true, permission: "edit-compartment"},
    component: () => import("@/views/departments/EditDepartmentPage.vue"),
  },
  // View Positions
  {
    path: "/positions",
    name: "PositionsPage",
    props: true,
    meta: { auth: true, permission: null},
    component: () => import("@/views/positions/PositionsPage.vue"),
  },
  // View Doctors
  {
    path: "/doctors",
    name: "DoctorsPage",
    props: true,
    meta: { auth: true, permission: null},
    component: () => import("@/views/doctors/DoctorsPage.vue"),
  },
  // Create Doctors
  {
    path: "/doctors/create",
    name: "CreateDoctorPage",
    props: true,
    meta: { auth: true, permission: "add-doctor"},
    component: () => import("@/views/doctors/CreateDoctorsPage.vue"),
  },
  // Edit Doctors
  {
    path: "/doctors/edit/:id",
    name: "EditDoctorPage",
    props: true,
    meta: { auth: true, permission: "edit-doctor"},
    component: () => import("@/views/doctors/EditDoctorsPage.vue"),
  },
  // View Orders
  {
    path: "/orders",
    name: "OrdersPage",
    props: true,
    meta: { auth: true, permission: "index-orders"},
    component: () => import("@/views/orders/OrdersPage.vue"),
  },
  // Create Orders
  {
    path: "/order/create",
    name: "CreateOrder",
    props: true,
    meta: { auth: true, permission: null},
    component: () => import("@/views/orders/CreateOrderPage.vue"),
  },
  // Edit Orders
  {
    path: "/order/edit/:id",
    name: "EditOrderPage",
    props: true,
    meta: { auth: true, permission: "edit-order"},
    component: () => import("@/views/orders/EditOrderPage.vue"),
  },
  // View Services
  {
    path: "/services",
    name: "ServicesPage",
    props: true,
    meta: { auth: true, permission: null},
    component: () => import("@/views/services/ServicesPage.vue"),
  },
  // Create Service
  {
    path: "/services/create",
    name: "CreateServicePage",
    props: true,
    meta: { auth: true, permission: "add-service"},
    component: () => import("@/views/services/CreateServicePage.vue"),
  },
  // Edit Service
  {
    path: "/services/edit/:id",
    name: "EditServicePage",
    props: true,
    meta: { auth: true, permission: "edit-service"},
    component: () => import("@/views/services/EditServicePage.vue"),
  },
  // View Posts
  {
    path: "/posts",
    name: "PostsPage",
    props: true,
    meta: { auth: true, permission: null},
    component: () => import("@/views/posts/PostsPage.vue"),
  },
  // Create Post
  {
    path: "/post/create",
    name: "CreatePostPage",
    props: true,
    meta: { auth: true, permission: "add-post"},
    component: () => import("@/views/posts/CreatePage.vue"),
  },
  // Edit Post
  {
    path: "/post/edit/:id",
    name: "EditPostPage",
    props: true,
    meta: { auth: true, permission: "edit-post"},
    component: () => import("@/views/posts/EditPage.vue"),
  },
  // View Packages
  {
    path: "/packages",
    name: "PackagesPage",
    props: true,
    meta: { auth: true, permission: null},
    component: () => import("@/views/packages/PackagesPage.vue"),
  },
  // Create Package
  {
    path: "/package/create",
    name: "CreatePackagePage",
    props: true,
    meta: { auth: true, permission: "add-service-pack"},
    component: () => import("@/views/packages/CreatePage.vue"),
  },
  // Edit Package
  {
    path: "/package/edit/:id",
    name: "EditPackagePage",
    props: true,
    meta: { auth: true, permission: "edit-service-pack"},
    component: () => import("@/views/packages/EditPage.vue"),
  },
  // View Services Categories
  {
    path: "/services/categories",
    name: "ServicesCategoriesPage",
    props: true,
    meta: { auth: true, permission: null},
    component: () => import("@/views/catsservices/CatsServicesPage.vue"),
  },
  // View SEO
  {
    path: "/seo",
    name: "SeoPage",
    props: true,
    meta: { auth: true, permission: "index-seo"},
    component: () => import("@/views/seo/SeoPage.vue"),
  },
  // View Other data
  {
    path: "/other",
    name: "OtherPage",
    props: true,
    meta: { auth: true, permission: null},
    component: () => import("@/views/other/OtherPage.vue"),
  },
  // View FAQ data
  {
    path: "/faq",
    name: "FAQPage",
    props: true,
    meta: { auth: true, permission: "index-faq"},
    component: () => import("@/views/faq/FAQPage.vue"),
  },
  // Create FAQ data
  {
    path: "/faq/create",
    name: "FAQCreatePage",
    props: true,
    meta: { auth: true, permission: "add-faq"},
    component: () => import("@/views/faq/Create.vue"),
  },
  // Edit FAQ data
  {
    path: "/faq/edit/:id",
    name: "FAQEditPage",
    props: true,
    meta: { auth: true, permission: "edit-faq"},
    component: () => import("@/views/faq/Edit.vue"),
  },
  // Chat
  {
    path: "/chat",
    name: "ChatPage",
    props: true,
    meta: { auth: true, permission: "guest-chat"},
    component: () => import("@/views/chat/ChatPage.vue"),
  },
  // View LOG data
  {
    path: "/logs",
    name: "LogsPage",
    props: true,
    meta: { auth: true, permission: "read-logs"},
    component: () => import("@/views/logs/LogsPage.vue"),
  },
  // View Roles data
  // {
  //   path: "/roles",
  //   name: "RolesPage",
  //   props: true,
  //   meta: { auth: true, permission: null},
  //   component: () => import("@/views/roles/RolesPage.vue"),
  // },
  // View Users data
  // {
  //   path: "/users",
  //   name: "UsersPage",
  //   props: true,
  //   meta: { auth: true, permission: null},
  //   component: () => import("@/views/users/UsersPage.vue"),
  // },
  // View Settings data
  {
    path: "/settings",
    name: "SettingsPage",
    props: true,
    meta: { auth: true, permission: null},
    component: () => import("@/views/settings/SettingsPage.vue"),
  },
  // View Currency data
  {
    path: "/currency",
    name: "CurrencyPage",
    props: true,
    meta: { auth: true, permission: 'index-currency-rate'},
    component: () => import("@/views/currency/CurrencyPage.vue"),
  },
  // View Profile data
  {
    path: "/profile",
    name: "ProfilePage",
    props: true,
    meta: { auth: true, permission: null},
    component: () => import("@/views/profile/ProfilePage.vue"),
  },
  // View Redirects data
  {
    path: "/redirects",
    name: "RedirectsPage",
    props: true,
    meta: { auth: true, permission: null},
    component: () => import("@/views/redirects/RedirectsPage.vue"),
  },
  // View 404 Error
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    meta: { auth: true, permission: null},
    component: () => import("@/views/ErrorPage.vue"),
  },
];

const router = createRouter({
  // eslint-disable-next-line no-undef
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  if (window.innerWidth < 1280) {
    store.commit("perm_ui/SET_SIDEBAR", false);
  }
  if (to.meta.auth === false && to.meta.permission === null){
    return true;
  }

  if (to.meta.auth === true && !store.state.auth?.user || to.meta.permission && !store.state.auth?.user?.permissions?.includes(to.meta.permission)) {
    return { name: "AuthLoginPage" };
  }
  return true;
});

export default router;
