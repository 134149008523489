<template>
  <a
    class="plate"
    @click="
      this.$store.commit(
        'perm_ui/SET_SIDEBAR',
        !this.$store.state.perm_ui.sidebar
      )
    "
  >
    <svg
      class="burger"
      xmlns="http://www.w3.org/2000/svg"
      xml:space="preserve"
      id="_x32_"
      fill="#000"
      stroke="#000"
      version="1.1"
      viewBox="0 0 512 512"
    >
      <g id="SVGRepo_iconCarrier">
        <path d="M0 16h512v96H0zM0 208h512v96H0zM0 400h512v96H0z" class="st0" />
      </g>
    </svg>
    <svg
      class="x"
      xmlns="http://www.w3.org/2000/svg"
      xml:space="preserve"
      fill="#31344b"
      stroke="#31344b"
      viewBox="0 0 512 512"
    >
      <path
        d="M512 59 453 0 256 197 59 0 0 59l197 197L0 453l59 59 197-197 197 197 59-59-197-197z"
      />
    </svg>
  </a>
</template>

<script>
export default {
  name: "BurgerMenu",
};
</script>

<style scoped lang="less">
.plate {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 42px;
  overflow: visible;
  position: relative;
  border: none;
  border-radius: var(--round-mid);
  color: var(--primary);
  cursor: pointer;
  box-sizing: border-box;
  background-blend-mode: soft-light, normal;
  // border: 2px solid var(--dark-color);
  // transition: 0.5s;
  background: var(--bg);
  box-shadow: -4px -4px 8px #ffffff, 4px 4px 8px rgb(174 174 192 / 60%);
  &.active {
    // transition: 0.5s;
    // box-shadow: -2px -2px 6px #bc81c7c6, 2px 2px 6px #1493d3d1;
  }
  &.hide--phone {
    // height: 28px;
  }
}
svg {
  width: 18px;
}
.burger {
  display: block;
}
.x {
  display: none;
}
.active .burger {
  display: none;
}
.active .x {
  display: block;
}
</style>
