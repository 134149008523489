export default {
  namespaced: true,

  state: {
    sidebar: true,
    view: {},
    sort: {
      orders: { name: "id", asc: false },
      services: { name: "ord", asc: true },
      catsServices: { name: "ord", asc: true },
      packages: { name: "id", asc: false },
      compartments: { name: "id", asc: false },
      departments: { name: "id", asc: false },
      positions: { name: "id", asc: false },
      doctors: { name: "ord", asc: true },
      blog: { name: "id", asc: false },
      seo: { name: "id", asc: false },
      other: { name: "id", asc: false },
      faq: { name: "ord", asc: true },
      media: { name: "id", asc: false },
      catsMedia: { name: "id", asc: false },
      gallery: { name: "id", asc: false },
      catsGallery: { name: "id", asc: false },
    },
    columnOrder: {},
    newOrders: false,
    pages: {}
  },
  mutations: {
    SET_SIDEBAR(state, value) {
      state.sidebar = value;
    },
    SET_VIEW(state, value) {
      state.view[value.page] = value.list;
    },
    SET_ORDER(state, value) {
      state.columnOrder[value.page] = value.list;
    },
    SET_PAGES(state, value) {
      if(state.pages[value.page]){
        state.pages[value.page][value.name] = value.data;
      } else {
        state.pages[value.page] = {};
        state.pages[value.page][value.name] = value.data;
      }
      
    },
    SET_NEW_ORDERS(state, value) {
      state.newOrders = value;
    },
  },
  actions: {
    async setSidebar({ commit }, state) {
      commit("SET_SIDEBAR", state);
    },
    async setView({ commit }, state) {
      commit("SET_VIEW", state);
    },
    async setOrder({ commit }, state) {
      commit("SET_ORDER", state);
    },
    async setNewOrders({ commit }, state) {
      commit("SET_NEW_ORDERS", state);
    },
  },
};
