export default {
  namespaced: true,

  state: {
    rooms: [],
    messages: [],
    unread: false,
  },
  getters:{
    countUnread(state){
      let unread = 0;
      state.rooms.map((room)=>{
        if(room.isUnread === '1') {
          unread++;
        } 
      })
      return unread;
    },
    getUnread(state){
      return state.unread;
    }
  },
  mutations: {
    SET_UNREAD(state, value) {
      state.unread = value;
    },
  },
  actions: {
    async setUnread({ commit }, unread) {
      commit("SET_UNREAD", unread);
    },
  },
};
