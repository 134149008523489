<template>
  <div
    class="sidebar__wrapper"
    :class="{ closed: !this.$store.state.perm_ui.sidebar }"
  >
    <div class="sidebar">
      <ul class="address-bar">
        <template v-if="this.authenticated">
          <li
            v-if="
              this.$store.state.auth.user?.permissions?.includes(
                'get-admin-counter'
              )
            "
          >
            <router-link
              v-tooltip="{
                content: 'Дашборд',
                disabled: this.$store.state.perm_ui.sidebar,
              }"
              :to="{ name: 'HomePage' }"
              :disabled="true"
            >
              <span>
                <img loading="lazy" src="@/assets/svg/home.svg" alt="" />
              </span>
              Дашборд
            </router-link>
          </li>
          <li
            v-if="
              this.$store.state.auth.user?.permissions?.includes('guest-chat')
            "
            :class="{ notification: this.chatUnread }"
          >
            <router-link
              v-tooltip="{
                content: 'Чат',
                disabled: this.$store.state.perm_ui.sidebar,
              }"
              :to="{ name: 'ChatPage' }"
              :disabled="true"
            >
              <span>
                <img loading="lazy" src="@/assets/svg/chat.svg" alt="" />
              </span>
              Чат
            </router-link>
          </li>
          <hr
            v-if="
              this.$store.state.auth.user?.permissions?.includes(
                'get-admin-counter'
              ) ||
              this.$store.state.auth.user?.permissions?.includes('guest-chat')
            "
          />

          <li
            v-if="
              this.$store.state.auth.user?.permissions?.includes('index-orders')
            "
            :class="{ notification: this.$store.state.perm_ui.newOrders }"
          >
            <router-link
              class="button--new"
              v-tooltip="{
                content: 'Додати замовлення',
              }"
              :to="{ name: 'CreateOrder', props: {} }"
            >
              <span> + </span>
            </router-link>
            <router-link
              v-tooltip="{
                content: 'Замовлення',
                disabled: this.$store.state.perm_ui.sidebar,
              }"
              :to="{ name: 'OrdersPage', props: {} }"
            >
              <span>
                <img loading="lazy" src="@/assets/svg/orders.svg" alt="" />
              </span>
              Замовлення
            </router-link>
          </li>
          <li>
            <router-link
              v-if="
                this.$store.state.auth.user?.permissions?.includes(
                  'add-service'
                )
              "
              class="button--new"
              v-tooltip="{
                content: 'Додати послугу',
              }"
              :to="{ name: 'CreateServicePage', props: {} }"
            >
              <span> + </span>
            </router-link>
            <router-link
              v-tooltip="{
                content: 'Послуги і Аналізи',
                disabled: this.$store.state.perm_ui.sidebar,
              }"
              :to="{ name: 'ServicesPage', props: {} }"
            >
              <span>
                <img loading="lazy" src="@/assets/svg/services.svg" alt="" />
              </span>
              Послуги і Аналізи
            </router-link>
            <router-link
              class="cat"
              v-tooltip="{
                content: 'Категорії послуг',
              }"
              :to="{ name: 'ServicesCategoriesPage', props: {} }"
            >
              <span>
                <img loading="lazy" src="@/assets/svg/cats.svg" alt="" />
              </span>
            </router-link>
          </li>
          <li>
            <router-link
              v-if="
                this.$store.state.auth.user?.permissions?.includes(
                  'add-service-pack'
                )
              "
              class="button--new"
              v-tooltip="{
                content: 'Додати пакет',
              }"
              :to="{ name: 'CreatePackagePage', props: {} }"
            >
              <span> + </span>
            </router-link>
            <router-link
              v-tooltip="{
                content: 'Пакети послуг',
                disabled: this.$store.state.perm_ui.sidebar,
              }"
              :to="{ name: 'PackagesPage', props: {} }"
            >
              <span>
                <img loading="lazy" src="@/assets/svg/pack.svg" alt="" />
              </span>
              Пакети послуг
            </router-link>
          </li>
          <li
            v-if="
              this.$store.state.auth.user?.permissions?.includes(
                'index-currency-rate'
              )
            "
          >
            <router-link
              v-tooltip="{
                content: 'Курс валют',
                disabled: this.$store.state.perm_ui.sidebar,
              }"
              :to="{ name: 'CurrencyPage', props: {} }"
            >
              <span>
                <img loading="lazy" src="@/assets/svg/currency.svg" alt="" />
              </span>
              Курс валют
            </router-link>
          </li>
          <hr />
          <li>
            <router-link
              v-tooltip="{
                content: 'Напрями',
                disabled: this.$store.state.perm_ui.sidebar,
              }"
              :to="{ name: 'CompartmentsPage', props: {} }"
            >
              <span>
                <img
                  loading="lazy"
                  src="@/assets/svg/compartments.svg"
                  alt=""
                />
              </span>
              Напрями
            </router-link>
          </li>
          <li>
            <router-link
              v-if="
                this.$store.state.auth.user?.permissions?.includes(
                  'add-compartment'
                )
              "
              class="button--new"
              v-tooltip="{
                content: 'Додати відділ',
              }"
              :to="{ name: 'CreateDepartmentPage', props: {} }"
            >
              <span> + </span>
            </router-link>
            <router-link
              v-tooltip="{
                content: 'Відділи',
                disabled: this.$store.state.perm_ui.sidebar,
              }"
              :to="{ name: 'DepartmentsPage', props: {} }"
            >
              <span>
                <img loading="lazy" src="@/assets/svg/departments.svg" alt="" />
              </span>
              Відділи
            </router-link>
          </li>
          <li>
            <router-link
              v-tooltip="{
                content: 'Посади',
                disabled: this.$store.state.perm_ui.sidebar,
              }"
              :to="{ name: 'PositionsPage', props: {} }"
            >
              <span>
                <img loading="lazy" src="@/assets/svg/positions.svg" alt="" />
              </span>
              Посади
            </router-link>
          </li>
          <li>
            <router-link
              v-if="
                this.$store.state.auth.user?.permissions?.includes('add-doctor')
              "
              class="button--new"
              v-tooltip="{
                content: 'Додати лікаря',
              }"
              :to="{ name: 'CreateDoctorPage', props: {} }"
            >
              <span> + </span>
            </router-link>
            <router-link
              v-tooltip="{
                content: 'Лікарі',
                disabled: this.$store.state.perm_ui.sidebar,
              }"
              :to="{ name: 'DoctorsPage', props: {} }"
            >
              <span>
                <img loading="lazy" src="@/assets/svg/doctor.svg" alt="" />
              </span>
              Лікарі
            </router-link>
          </li>
          <hr />

          <li>
            <router-link
              v-if="
                this.$store.state.auth.user?.permissions?.includes('add-post')
              "
              class="button--new"
              v-tooltip="{
                content: 'Додати пост',
              }"
              :to="{ name: 'CreatePostPage', props: {} }"
            >
              <span> + </span>
            </router-link>
            <router-link
              v-tooltip="{
                content: 'Пости',
                disabled: this.$store.state.perm_ui.sidebar,
              }"
              :to="{ name: 'PostsPage', props: {} }"
            >
              <span>
                <img loading="lazy" src="@/assets/svg/news.svg" alt="" />
              </span>
              Блог
            </router-link>
          </li>

          <li
            v-if="
              this.$store.state.auth.user?.permissions?.includes('index-seo')
            "
          >
            <router-link
              v-tooltip="{
                content: 'SEO',
                disabled: this.$store.state.perm_ui.sidebar,
              }"
              :to="{ name: 'SeoPage', props: {} }"
            >
              <span>
                <img loading="lazy" src="@/assets/svg/seo.svg" alt="" />
              </span>
              SEO
            </router-link>
          </li>
          <li
            v-if="
              this.$store.state.auth.user?.permissions?.includes(
                'edit-common-data'
              )
            "
          >
            <router-link
              v-tooltip="{
                content: 'Загальні дані',
                disabled: this.$store.state.perm_ui.sidebar,
              }"
              :to="{ name: 'OtherPage', props: {} }"
            >
              <span style="height: 28px">
                <img
                  style="height: 23px; margin: auto; transform: translateY(2px)"
                  src="@/assets/svg/list.svg"
                  alt=""
                />
              </span>
              Загальні дані
            </router-link>
          </li>
          <li
            v-if="
              this.$store.state.auth.user?.permissions?.includes('index-faq')
            "
          >
            <router-link
              v-if="
                this.$store.state.auth.user?.permissions?.includes('add-faq')
              "
              class="button--new"
              v-tooltip="{
                content: 'Додати запитання',
              }"
              :to="{ name: 'FAQCreatePage', props: {} }"
            >
              <span> + </span>
            </router-link>
            <router-link
              v-tooltip="{
                content: 'FAQ',
                disabled: this.$store.state.perm_ui.sidebar,
              }"
              :to="{ name: 'FAQPage', props: {} }"
            >
              <span style="height: 28px">
                <img
                  style="height: 23px; margin: auto; transform: translateY(2px)"
                  src="@/assets/svg/faq.svg"
                  alt=""
                />
              </span>
              FAQ
            </router-link>
          </li>
          <hr />
          <li>
            <router-link
              v-tooltip="{
                content: 'Медіа-файли',
                disabled: this.$store.state.perm_ui.sidebar,
              }"
              :to="{ name: 'MediaPage', props: {} }"
            >
              <span>
                <img loading="lazy" src="@/assets/svg/media.svg" alt="" />
              </span>
              Медіа-файли
            </router-link>
            <router-link
              class="cat"
              v-tooltip="{
                content: 'Категорії медіа',
              }"
              :to="{ name: 'CatsMediaPage', props: {} }"
            >
              <span>
                <img loading="lazy" src="@/assets/svg/cats.svg" alt="" />
              </span>
            </router-link>
          </li>
          <li>
            <router-link
              v-tooltip="{
                content: 'Галерея',
                disabled: this.$store.state.perm_ui.sidebar,
              }"
              :to="{ name: 'GalleryPage', props: {} }"
            >
              <span>
                <img loading="lazy" src="@/assets/svg/gallery.svg" alt="" />
              </span>
              Галерея
            </router-link>
            <router-link
              class="cat"
              v-tooltip="{
                content: 'Категорії галереї',
              }"
              :to="{ name: 'CatsGalleryPage', props: {} }"
            >
              <span>
                <img loading="lazy" src="@/assets/svg/cats.svg" alt="" />
              </span>
            </router-link>
          </li>
          <hr />
          <!-- <li v-if="true">
            <router-link
              v-tooltip="{
                content: 'Користувачі',
                disabled: this.$store.state.perm_ui.sidebar,
              }"
              :to="{ name: 'UsersPage', props: {} }"
            >
              <span>
                <img loading="lazy" src="@/assets/svg/users.svg" alt="" />
              </span>
              Користувачі
            </router-link>
          </li>
          <li v-if="$store.state.auth.user?.roles.includes('Розробник')">
            <router-link
              v-tooltip="{
                content: 'Ролі та Права',
                disabled: this.$store.state.perm_ui.sidebar,
              }"
              :to="{ name: 'RolesPage', props: {} }"
            >
              <span>
                <img loading="lazy" src="@/assets/svg/roles.svg" alt="" />
              </span>
              Ролі та Права
            </router-link>
          </li> -->
          <li v-if="$store.state.auth.user">
            <router-link
              v-tooltip="{
                content: 'Профіль',
                disabled: this.$store.state.perm_ui.sidebar,
              }"
              :to="{ name: 'ProfilePage', props: {} }"
            >
              <span>
                <img loading="lazy" src="@/assets/svg/profile.svg" alt="" />
              </span>
              Профіль
            </router-link>
          </li>
          <li
            v-if="
              this.$store.state.auth.user?.permissions?.includes('read-logs')
            "
          >
            <router-link
              v-tooltip="{
                content: 'Логи',
                disabled: this.$store.state.perm_ui.sidebar,
              }"
              :to="{ name: 'LogsPage', props: {} }"
            >
              <span>
                <img loading="lazy" src="@/assets/svg/logs.svg" alt="" />
              </span>
              Логи
            </router-link>
          </li>
          <li v-if="$store.state.auth.user?.roles.includes('Розробник')">
            <router-link
              v-tooltip="{
                content: 'Системні налаштування',
                disabled: this.$store.state.perm_ui.sidebar,
              }"
              :to="{ name: 'SettingsPage', props: {} }"
            >
              <span>
                <img loading="lazy" src="@/assets/svg/settings.svg" alt="" />
              </span>
              Система
            </router-link>
          </li>
          <li
            v-if="
              this.$store.state.auth.user?.permissions?.includes(
                'index-redirect'
              )
            "
          >
            <router-link
              v-tooltip="{
                content: 'Редиректи',
                disabled: this.$store.state.perm_ui.sidebar,
              }"
              :to="{ name: 'RedirectsPage', props: {} }"
            >
              <span>
                <img loading="lazy" src="@/assets/svg/redirect.svg" alt="" />
              </span>
              Редиректи
            </router-link>
          </li>
          <li style="margin-top: 40px; margin-bottom: 20px">
            <router-link
              v-tooltip="{
                content: 'Вийти',
                disabled: this.$store.state.perm_ui.sidebar,
              }"
              @click="logout"
              :to="{ name: 'AuthLoginPage', props: {} }"
            >
              <span>
                <img loading="lazy" src="@/assets/svg/logout.svg" alt="" />
              </span>
              Вийти
            </router-link>
          </li>
        </template>

        <li v-if="!this.authenticated">
          <router-link
            v-tooltip="{
              content: 'Авторизація',
              disabled: this.$store.state.perm_ui.sidebar,
            }"
            :to="{ name: 'AuthLoginPage', props: {} }"
          >
            <span>
              <img loading="lazy" src="@/assets/svg/login.svg" alt="" />
            </span>
            Авторизація
          </router-link>
        </li>
      </ul>
      <div class="sidebar__toolbar">
        <burger-menu
          class="hide--phone"
          :class="{ active: this.$store.state.perm_ui.sidebar }"
        ></burger-menu>
      </div>
    </div>
  </div>
</template>

<script>
import BurgerMenu from "@/components/UI/BurgerMenu.vue";
import orderSound from "@/assets/order.mp3";
import chatSound from "@/assets/chat.mp3";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "SidebarMenu",
  components: {
    BurgerMenu,
  },
  data() {
    return {
      state: this.user,
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      chatUnread: "data/getUnread",
    }),
  },
  mounted() {
    if (
      this.$store.state.auth.authenticated &&
      this.$store.state.auth.user?.permissions?.includes("guest-chat")
    ) {
      this.getUnread();

      window.Echo.private("guest-chat").listen(
        ".guest-chat.message.sent",
        () => {
          this.$store.dispatch("data/setUnread", true);

          const audio = new Audio(chatSound);
          audio.play();
        }
      );
    }
    if (
      this.$store.state.auth.authenticated &&
      this.$store.state.auth.user?.permissions?.includes("index-orders")
    ) {
      window.Echo.channel("order").listen(".order.created", () => {
        this.$store.dispatch("perm_ui/setNewOrders", true);
        const audio = new Audio(orderSound);
        audio.play();
      });
    }
  },
  methods: {
    ...mapActions({
      signOut: "auth/signOut",
      storeUnread: "data/setUnread",
    }),
    logout() {
      this.signOut();
    },
    getUnread() {
      this.axios
        .get("/api/chat/guest/messages")
        .then((r) => {
          let messages = r.data.data;
          let unreadPackages = messages.filter(
            (message) => message.isUnread === "1"
          );

          if (unreadPackages.length > 0) {
            this.$store.dispatch("data/setUnread", true);
          } else {
            this.$store.dispatch("data/setUnread", false);
          }
        })
        .catch((e) => {
          window.catchSample(e);
        });
    },
  },
};
</script>

<style scoped lang="less">
.sidebar__wrapper {
  padding: 10px;
  background: var(--bg);
  background-blend-mode: soft-light, normal;
  // transition: 1s;
  @media (max-width: 1024px) {
    position: fixed;
    z-index: 8;
    height: 100%;
  }

  @media (max-width: 767px) {
    transform: translateX(-260px);
    transition: 0.25s;
    left: 100%;
  }

  &.closed {
    @media (min-width: 768px) {
      .sidebar {
        padding: 0;
        padding-bottom: 80px;
        border-radius: var(--round-high);
        border-radius: var(--round-mid);
        box-shadow: inset -2px -2px 4px #fafbff, inset 2px 2px 4px 0px #a6abbd;
        background: transparent;

        @media (min-width: 768px) {
          @media (max-width: 1024px) {
            transition: 0.25s;
            animation-timing-function: linear;
          }

          max-width: 70px;
          // transition: 0.25s;
        }

        @media (max-width: 1024px) {
          transition: 0.25s;
          animation-timing-function: linear;
        }

        .address-bar {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0;
          padding-top: 6px;
          margin-top: 0;

          @media (min-width: 768px) {
            // align-items: center;
          }
          li {
            flex-direction: column;
            justify-content: center;
          }

          a {
            padding: 6px;
            white-space: nowrap;
            box-sizing: content-box;
            overflow: hidden;
            border: 1px solid transparent;

            @media (max-width: 1024px) {
              transition: 0.25s;
              animation-timing-function: linear;
            }

            @media (min-width: 768px) {
              width: 28px;
            }

            &.button--new {
              display: none !important;
            }
          }

          a.router-link-active {
            background-blend-mode: soft-light, normal;
            box-shadow: inset -2px -2px 2px #fafbff, inset 2px 2px 2px #a6abbdab;
            span:before {
              // width: 40px;
              // height: 40px;
            }
          }
        }
      }
    }

    @media (max-width: 767px) {
      transform: translateX(0px);
    }
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 240px;
  max-width: 240px;
  // transition: 0.25s;
  height: 100%;
  flex-shrink: 0;
  padding: 0px 0 60px 0;
  position: relative;
  z-index: 0;
  border-radius: 24px;
  border-radius: var(--round-mid);

  @media (max-width: 1024px) {
    transition: 0.25s;
    animation-timing-function: linear;
  }

  @media (max-width: 767px) {
    padding-bottom: 0;
  }

  box-shadow: inset -7px -7px 14px #fafbff, inset 7px 7px 14px #a6abbdab;
  box-shadow: inset -3px -3px 4px #fafbff, inset 3px 3px 4px 0px #a6abbd;
}

hr {
  border: none;
  border-bottom: 1px solid #fafbff;
  width: 50%;
  margin: 5px 25%;
  border-radius: var(--round-high);
  box-shadow: -1px -1px 2px #fafbff, 1px 1px 2px 0px #a6abbdab;
}

.notification:after {
  content: "!";
  position: absolute;
  width: 16px;
  height: 16px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  background-color: rgb(215, 65, 65);
  color: #fff;
  left: 30px;
  top: 0;
  z-index: 2;
  text-align: center;
  border-radius: var(--round-high);
}

.address-bar {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 6px;

  li {
    width: auto;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 10px;

    @media (max-width: 1024px) {
      transition: 0.25s;
      animation-timing-function: linear;
    }

    &:hover {
      .button--new {
        display: block;
        text-align: center;
        transform: scale(1);
        transition: 0.2s;
        pointer-events: all;
        margin-left: 10px;
      }
    }
  }

  a {
    display: inline-flex;
    align-items: center;
    color: var(--dark-color);
    font-weight: 400;
    font-size: 14px;
    width: auto;
    padding: 6px;
    padding-right: 12px;
    line-height: 24px;
    border: 1px solid transparent;
    border-radius: var(--round-high);
    border-radius: var(--round-mid);
    white-space: nowrap;

    @media (max-width: 1024px) {
      transition: 0.25s;
      animation-timing-function: linear;
    }

    &:hover {
      background-blend-mode: soft-light, normal;
      border: 1px solid rgba(255, 255, 255, 0.4);
      box-shadow: -2px -2px 4px #fafbff, 2px 2px 4px 0px #a6abbdab;
      transition: 0.25s;
    }

    &.cat {
      padding: 5px;
      margin-left: auto;

      > span {
        margin-right: 0;
      }
    }

    &.router-link-active {
      background-blend-mode: soft-light, normal;
      box-shadow: inset -2px -2px 2px #fafbff, inset 2px 2px 2px #a6abbdab;
      border-radius: var(--round-mid);
      span:before {
        // content: "";
        opacity: 1;
        width: 36px;
        height: 36px;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* background-color: #ffffff; */
        background-blend-mode: soft-light, normal;
        border: 1px solid rgba(255, 255, 255, 0.4);
        box-shadow: inset -2px -2px 2px #fafbff, inset 2px 2px 2px #a6abbdab;
        border-radius: var(--round-mid);
        transition: 1s;
      }

      span img {
        position: relative;
        z-index: 1;
      }
    }

    &.button--new {
      width: 36px;
      height: 36px;
      padding: 7px;
      font-size: 26px;
      line-height: 24px;
      font-weight: 400;
      color: var(--primary);
      position: absolute;
      left: 2px;
      border: none !important;
      background-color: var(--bg) !important;
      background-blend-mode: soft-light, normal !important;
      box-shadow: inset -2px -2px 2px #fafbff, inset 2px 2px 2px #a6abbdab !important;
      transform: scale(0);
      transition: 0.2s;
      z-index: 2;
      pointer-events: none;

      &:hover {
        + a {
          background-blend-mode: soft-light, normal;
          border: 1px solid rgba(255, 255, 255, 0.4);
          box-shadow: -2px -2px 4px #fafbff, 2px 2px 4px 0px #a6abbdab;
          transition: 0.5s;
        }
      }

      &.router-link-active {
        display: none;
      }

      span {
        min-width: unset;
        margin: 0;
        line-height: 21px;
        vertical-align: top;
      }
    }

    img,
    svg {
      display: block;
      height: 28px;
      width: auto;
      line-height: 24px;
    }

    span {
      min-width: 28px;
      margin-right: 12px;
      position: relative;

      &:before {
        opacity: 0;
        width: 36px;
        height: 36px;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* background-color: #ffffff; */
        background-blend-mode: soft-light, normal;
        border: 1px solid rgba(255, 255, 255, 0.4);
        box-shadow: inset -2px -2px 2px #fafbff, inset 2px 2px 2px #a6abbdab;
        border-radius: var(--round-mid);
        transition: 1s;
      }
    }
  }
}

.sidebar__toolbar {
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
