import Vuex from "vuex";
import auth from "@/store/auth";
import ui from "@/store/ui";
import perm_ui from "@/store/perm_ui";
import pages from "@/store/pages";
import data from "@/store/data";
import createPersistedState from "vuex-persistedstate";

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ["auth", "perm_ui", "data", "pages"],
    }),
  ],
  modules: {
    auth,
    ui,
    perm_ui,
    data,
    pages
  },
});
