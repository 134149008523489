<template>
  <div v-if="text !== ''" class="modal alert-modal">
    <div class="modal__content" :class="type" @click="hideDialog">
      <p v-for="(finalText, index) in alertText" :key="index">
        {{ finalText }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseAlert",
  props: {
    text: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "success",
    },
  },
  data() {
    return {
      alertText: [],
    };
  },
  created() {
    setTimeout(this.hideDialog, 3000);
    this.setText(this.text);
  },
  methods: {
    hideDialog() {
      this.$store.state.ui.alert.text = null;
    },
    setText(text) {
      if (typeof text === "object") {
        const tempArray = Object.entries(text);
        for (let i = 0; i < tempArray.length; i += 1) {
          this.alertText.push(tempArray[i][1][0]);
        }
      } else {
        this.alertText.push(text);
      }
    },
  },
};
</script>

<style scoped lang="less">
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  padding: 10px;
  pointer-events: none;
  overflow-y: auto;
  background-color: transparent;
  @media (max-width: 767px) {
  }
}
.modal__content {
  padding: 25px;
  border-radius: var(--round-mid);
  background-color: #fff;
  position: absolute;
  pointer-events: all;
  cursor: pointer;
  max-width: 300px;
  top: 40px;
  right: 40px;
  @media (max-width: 767px) {
    top: unset;
    bottom: 10px;
    left: 10px;
    right: 10px;
    max-width: 100%;
  }
}
.modal__content.success {
  box-shadow: 0 10px 50px -5px #38984f63;
}
.modal__content.error {
  box-shadow: 0 10px 50px -5px #b91a1a63;
}
</style>
