<template>
  <a
    class="button--new"
    @click="
      this.$store.commit('pages/SET_PAGES', {
        page: [this.page],
        name: 'view',
        data: !this.$store.state.pages[this.page].view,
      })
    "
  >
    <img
      style="width: 16px"
      v-if="this.$store.state.pages[this.page].view"
      src="@/assets/svg/table.svg"
      alt=""
    />
    <img
      loading="lazy"
      v-else
      style="width: 18px"
      src="@/assets/svg/cards.svg"
      alt=""
    />
  </a>
</template>

<script>
export default {
  name: "BurgerMenu",
  props: {
    page: {
      type: String,
    },
  },
};
</script>

<style scoped>
a {
  justify-content: center;
  align-items: center;
  padding: 5px;
}
</style>
