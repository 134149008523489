import axios from "axios";
import router from "@/router";

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: {},
  },

  getters: {
    authenticated(state) {
      return state.authenticated;
    },
    user(state) {
      return state.user;
    },
  },

  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },

    SET_USER(state, value) {
      state.user = value;
    },
  },

  actions: {
    async signIn({ commit }, credentials) {
      await axios.get("/api/sanctum/csrf-cookie");
      await axios
        .post("/api/auth/login", credentials)
        .then((response) => {
          commit("SET_AUTHENTICATED", true);
          commit("SET_USER", response.data);
          window.slert("Ви успішно авторизувалися!");

          axios
            .get("/api/chat/guest/messages")
            .then((r) => {
              let messages = r.data.data;
              let unreadPackages = messages.filter((message)=> message.isUnread === '1');
    
              if(unreadPackages.length > 0){
                this.dispatch("data/setUnread", true, {root:true});
              } else {
                this.dispatch("data/setUnread", false, {root:true});
              }
              router.push("/");
            })
            .catch((e) => {
              window.catchSample(e);
            });
        })
        .catch((e) => {
          window.catchSample(e);
          this.signOut;
        });
    },
    async signOut({ commit }) {
      commit("SET_AUTHENTICATED", false);
      commit("SET_USER", null);
      router.push("/login");
    },
  },
};
